import React, { useEffect, useRef, useState } from "react";
import TrendBar from "../../../Project/projectDashboard/trend/TrendBar";
import NormalSelect from "../../../../components/select/normalSelect";
import instance from "../../../../utils/axiosHelper";
import { Box } from "@mui/material";
import StackedColumnChart from "../../../../components/stackedColumnChart/StackedColumnChart";
import { addSpace } from "../../../../utils/spaceSeprator";

const DefectTimeTrend = ({ issueType, projectId, boardId, sprintId }) => {
  const [iteration, setIteration] = useState(7);
  const [data, setData] = useState([]);
  const myDivRef = useRef(null);

  console.log(issueType, projectId, boardId, sprintId);

  useEffect(() => {
    if (issueType === "Avg Defect Lead Time") {
      let url = `/api/v2/sprints/trend/avgDefectLeadTime?board_id=${boardId}&sprint_id=${sprintId}&project_id=${projectId}&iteration=${iteration}`;
      api(url);
    } else if (issueType === "Avg Lead Time") {
      let url = `/api/v2/sprints/trend/avgLeadTime?board_id=${boardId}&sprint_id=${sprintId}&project_id=${projectId}&iteration=${iteration}`;
      api(url);
    } else if (issueType === "Sprint completion rate") {
      let url = `/api/v2/sprints/trend/sprintCompletionRate?board_id=${boardId}&sprint_id=${sprintId}&project_id=${projectId}&iteration=${iteration}`;
      api(url);
    }
  }, [issueType, iteration, boardId, sprintId, projectId]);

  const api = (url) => {
    instance
      .get(url)
      .then((response) => {
        console.log(response);
        if (issueType === "Avg Defect Lead Time") {
          const data = response.data.map((e) => {
            return {
              category: e.startDate,
              value: e.value,
            };
          });
          setData(data);
        } else if (issueType === "Avg Lead Time") {
          const data = response.data.map((e) => {
            return {
              category: e.startDate,
              value: e.value,
            };
          });
          setData(data);
        } else if (issueType === "Sprint completion rate") {
          const sprintData = response?.data?.map((e) => {
            return {
              category: e.startDate,
              "Work Commitment": e?.WorkCommitment,
              "Work Done": e?.WorkDone,
              "Work Done In Time": e?.WorkDoneInTime,
              "Work Added Before Sprint": e?.WorkAddedBeforeSprint,
              "Scope Added": e?.ScopeAdded,
              "Value": e?.Value,
              "Scope Removed": e?.ScopeRemoved,
              "Work In Progress": e?.WorkInProgress
            };
          });
          setData(sprintData);
        }
        scrollToDiv();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const scrollToDiv = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const trendLabel = (Key) => {
    switch (Key) {
      case "Avg Defect Lead Time":
        return "Avg Defect Lead Time";
      case "Avg Lead Time":
        return "Avg Lead Time";

      default:
        break;
    }
  };

  const label = trendLabel(issueType);

  return (
    <Box ref={myDivRef}>
      <Box width={200} my={1}>
        <NormalSelect
          option={[
            { label: "Weekly", value: 7 },
            { label: "Daily", value: 1 },
          ]}
          value={iteration}
          handleChange={(value) => setIteration(value)}
        />
      </Box>
      {issueType === "Sprint completion rate" ? (
        <StackedColumnChart
          graphData={data}
          yAxesLabel={label}
          height={"800px"}
        />
      ) : (
        <TrendBar graphData={data} yAxesLabel={label} issueType={issueType} />
      )}
    </Box>
  );
};

export default DefectTimeTrend;
