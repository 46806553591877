import { Avatar, Box, Card, Divider, Tooltip } from "@mui/material";
import React from "react";
import SelectInvestmentProfile from "../../../../components/select/selectInvestmentProfile";
import nextArrow from "../../../../assets/nextArrow.png";
import submiterImg from "../../../../assets/submiterandreview.jpg";

const SubmitterReviewer = ({
  handleGetVal,
  type,
  isSubmitterOrReviewer,
  handleSubReviewer,
  selectedSubReview,
  subAndReviewLink,
}) => {
  const handleChange = (event) => {
    handleGetVal(event.target.value);
  };

  const getDarkColor = () => {
    const r = Math.floor(Math.random() * 100); // Red (0-100)
    const g = Math.floor(Math.random() * 100); // Green (0-100)
    const b = Math.floor(Math.random() * 100); // Blue (0-100)
    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  };

  return (
    <div>
      <Card className="my-4 pb-2">
        <Box pt={4} px={4}>
          <SelectInvestmentProfile
            handleChange={handleChange}
            list={["Reviewer", "Submitter"]}
            value={type}
            iteration={true}
          />
          <SelectInvestmentProfile
            handleChange={(e) => handleSubReviewer(e.target.value)}
            list={isSubmitterOrReviewer}
            value={selectedSubReview}
            iteration={true}
          />
        </Box>

        {selectedSubReview ? (
          <div className="align-items-center justify-content-around">
            <div className="d-flex align-items-center justify-content-center">
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  marginRight: 1,
                  bgcolor: "#345348",
                }}
              >
                {subAndReviewLink?.ResourceName[0]}
              </Avatar>
              {subAndReviewLink?.ResourceName}
            </div>
            <Box width={100} height={80} margin={"0px auto"} mt={6}>
              <Tooltip
                title={
                  <div>{`${
                    subAndReviewLink?.ResourceName
                  } is ${type} ${subAndReviewLink?.Relations?.map(
                    (e) => e
                  ).join(",")} is ${
                    type === "Reviewer" ? "Submitter" : "Reviewer"
                  }`}</div>
                }
                placement="right"
                arrow
              >
                <img
                  src={nextArrow}
                  alt="next"
                  className="img-fluid"
                  style={{ transform: `rotate(${90}deg)` }}
                />
              </Tooltip>
            </Box>
            <div className="d-flex  justify-content-center">
              {subAndReviewLink?.Relations?.map((name, index) => (
                <Box
                  key={index}
                  display={"flex"}
                  pb={1}
                  alignItems={"center"}
                  mx={2}
                >
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      marginRight: 1,
                      // bgcolor: `#${(Math.random() * 435).toFixed()}649`,
                      bgcolor: getDarkColor,
                    }}
                  >
                    {name[0]}
                  </Avatar>
                  {name}
                </Box>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center mb-3">
            <img
              src={submiterImg}
              alt="submit"
              className="img-fluid"
              width={200}
            />
            <div style={{ color: "gray" }} className="px-2" >
              {`Please Select ${
                type === "Submitter" ? "Reviewer" : "Submitter"
              }`}{" "}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default SubmitterReviewer;
