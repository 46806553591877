import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function MultipleTooltipTrend({ graphData, yAxesLabel }) {
  useEffect(() => {
    if (!graphData || graphData.length === 0) {
      console.error("No data provided for the chart");
      return;
    }

    // Create chart instance
    const chart = am4core.create("chartdiv", am4charts.XYChart);

    // Add data
    chart.data = graphData;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "StartDate"; // Map the x-axis to StartDate
    categoryAxis.title.text = "Date";
    categoryAxis.renderer.grid.template.location = 0;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yAxesLabel;

    // Create a single series for averageKSI
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "Average KSI";
    series.dataFields.valueY = "averageKSI"; // Show only averageKSI as the series
    series.dataFields.categoryX = "StartDate";

    // Tooltip dynamically includes additional fields
    series.tooltipText = `
      {name}: [bold]{valueY}[/]
      PR Count: [bold]{prCount}[/]
      Available Reviewers: [bold]{availableReviewers}[/]
      Active Reviewers: [bold]{activeReviewers}[/]
      Submitters: [bold]{submitters}[/]
    `;
    series.columns.template.adapter.add("fill", (fill, target) => {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    return () => {
      chart.dispose();
    };
  }, [graphData, yAxesLabel]);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }} />;
}

export default MultipleTooltipTrend;
