import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { format } from "date-fns";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { GlobalData } from "../../../../../context/globalData";
import { datePayload } from "../../../../../utils/moduleTabApi";
import instance from "../../../../../utils/axiosHelper";
import { addSpace } from "../../../../../utils/spaceSeprator";
import CommonTable from "../../../../../components/commonTable";
import ExecutionStatus from "./ececutionStatus";

function IssueLevelRiskSummary({ issueType, projectId, epic }) {
  const { projectRangePickerValue, setIsDefinaionPopup, setDefinationKPI } =
    useContext(GlobalData);
  const [dataList, setDataList] = useState([]);
  const [columnName, setColumnName] = useState([]);
  const [date, setDate] = useState(projectRangePickerValue);

  console.log(issueType, "<=====issueType");

  useEffect(() => {
    const dateUrl = datePayload(date);
    if (issueType?.values === "To Do") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=${issueType?.values}`;
      api(url);
    } else if (issueType?.values === "In Progress") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=${issueType?.values}`;
      api(url);
    } else if (issueType?.values === "On Hold") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=${issueType?.values}`;
      api(url);
    } else if (issueType?.values === "CommitWithoutPr") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=CommitWithoutPR`;
      api(url);
    } else if (issueType?.values === "StalePr") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=StalePr`;
      api(url);
    } else if (issueType?.values === "LongRunningPr") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=LongRunningPr`;
      api(url);
    } else if (issueType?.values === "PrWithoutCommit") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=PrWithoutCommit`;
      api(url);
    } else if (issueType?.values === "PRwithoutComment") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=PrWithoutComment`;
      api(url);
    } else if (issueType?.values === "CommitsWithoutTicket") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=CommitsWithoutTicket`;
      api(url);
    } else if (issueType?.values === "CommitsWithHighChurn") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=CommitsWithHighChurn`;
      api(url);
    } else if (issueType?.values === "UnplannedTasks") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=UnplannedTasks`;
      api(url);
    } else if (issueType?.values === "UnreviewedPR") {
      let url = `/api/v2/ph/rootCauseAnalysis/details/${projectId}?${dateUrl}&epic=${epic}&type=UnreviewedPR`;
      api(url);
    }
  }, [issueType, date, epic]);

  const api = (url) => {
    instance(url)
      .then((res) => {
        setDataList(res?.data);
        const data = Object.keys(
          res?.data?.length > 0 ? res?.data[0] : {}
        )?.filter((e) => {
          if (
            e !== "tableData" &&
            e !== "TaskLink" &&
            e !== "CommitLink" &&
            e !== "Link"
          ) {
            return e;
          }
        });
        const columns = data?.map((e) => {
          return {
            field: e,
            title:
              e === "StalePr" ||
              e === "LongRunningPr" ||
              e === "Refactor %" ||
              e === "Rework %" ||
              e === "Feature %" ||
              e === "New Feature %" ||
              e === "Refactor" ||
              e === "New Feature (Lines)" ||
              e === "Code Churn %" ||
              e === "Code Churn (Lines)" ||
              e === "Rework" ||
              e === "Feature" ||
              e === "RiskyChangesPr" ? (
                <>
                  <span className="text-uppercase">{addSpace(e)}</span>
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI(e);
                    }}
                  >
                    <InfoIcon style={{ color: "gray" }} />
                  </span>
                </>
              ) : (
                <span className="text-uppercase">{addSpace(e)}</span>
              ),
            render: (data) => {
              if (e === "TaskId") {
                return (
                  <a target="_blank" href={data["TaskLink"]} rel="noreferrer">
                    {data.TaskId}
                  </a>
                );
              } else if (e === "CommitId") {
                return (
                  <a target="_blank" href={data["CommitLink"]} rel="noreferrer">
                    {data.CommitId}
                  </a>
                );
              } else if (e === "Id") {
                return (
                  <a target="_blank" href={data["Link"]} rel="noreferrer" className="fw-bold">
                    {data.Id}
                  </a>
                );
              } else if (e === "id") {
                return (
                  <a target="_blank" href={data["Link"]} rel="noreferrer" className="fw-bold"> 
                    {data.id}
                  </a>
                );
              } else if (e === "CreatedTime" || e === "CreatedDate" || e === "UpdatedDate") {
                return data[e] ? format(new Date(data[e]), "dd-MMM-yyy") : "";
              } else if (e === "id") {
                return (
                  <a target="_blank" href={data["url"]} rel="noreferrer">
                    {data.id}
                  </a>
                );
              } else if (e === "Status") {
                return (
                  <ExecutionStatus
                    status={data?.Status}
                  >
                    {data?.Status}
                  </ExecutionStatus>
                );
              } else if (e === "MergedDate") {
                return (
                  <>
                    {data?.MergedDate
                      ? format(new Date(data?.MergedDate), "dd-MMM-yyyy")
                      : "--"}
                  </>
                );
              } else {
                return data[e];
              }
            },
          };
        });

        setColumnName(columns);
      })
      .catch((error) => {
        setColumnName([]);
        setDataList([]);
        console.log(error);
      });
  };

  return (
    <div>
      <CommonTable columns={columnName} data={dataList} />
    </div>
  );
}

export default IssueLevelRiskSummary;
