import React from "react";

const ExecutionStatus = ({ status, children }) => {
  const getStatus = (item) => {
    switch (item) {
      case "Done":
        case "green":
          case "On Time":
            return "Done";
      case "Open":
        return "Open";
        case "To do":
      case "To Do":
      case "On hold":
      case "In Progress":
        return "yellow";
      case "gray":
        return "grayColor";
      default:
        if (item.includes("delayed")) {
          return "Delays";
        } else if (item.includes("Slipping")) {
          return "Delays";
        }
    }
  };
  return <div className={getStatus(status)}>{children}</div>;
};

export default ExecutionStatus;
