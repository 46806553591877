import { Box, Button, Card, Drawer, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import WorkAlocationChart from "../../projectDashboard/graphs/workAlocationChart";
import SelectInvestmentProfile from "../../../../components/select/selectInvestmentProfile";
import SelectField from "../../../../components/select/selectField";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import RangeSelector from "../../../../components/rangeSelector";
import { GlobalData } from "../../../../context/globalData";
import axios from "../../../../utils/axiosHelper";
import IssueLevel from "../IssueLevel";
import CommonTable from "../../../../components/commonTable";
import { format } from "date-fns";
import CheckSelect from "../../../../components/select/CheckSelect";
import { config } from "../../../../Config/permission";

export const EpicDistribution = ({ isSprint = false, projectId, sprintId }) => {
  const [selectedSubStatus, setSelectedSubStatus] = useState([]);
  const [selectedSubStatusUrl, setSelectedSubStatusUrl] = useState("");
  const [openRange, setOpenRange] = useState();
  const [statusList, setStatusList] = useState([]);
  const [allEpicList, setAllEpicList] = useState([]);
  const [filteredEpic, setFilteredEpic] = useState([]);
  const [issueLevelEpics, setIssueLevelEpics] = useState([]);
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [isSummary, setIsSummary] = useState(false);

  const column = [
    {
      title: "Task Id",
      field: "TaskId",
      render: (row) => (
        <a href={row?.TaskLink} target="_blank" rel="noreferrer">
          {row?.TaskId}
        </a>
      ),
    },
    {
      title: "Assignee Name",
      field: "Assignee",
    },
    {
      title: "Task Summary",
      field: "TaskSummary",
    },
    {
      title: "Epic Name",
      field: "Epic",
    },
    {
      title: "Created Time",
      field: "CreatedTime",
      render: (row) => format(new Date(row?.CreatedTime), "dd-MMM-yyyy"),
    },
    {
      title: "Task Status",
      field: "TaskStatus",
    },
  ];

  //  statusNames
  useEffect(() => {
    const url = `/api/v2/statusNames/${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const status = response?.data?.StatusNames[0]?.Names.map((e) => e);
        setStatusList(status);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId]);

  const getCheckUrl = (prefix, items) => {
    if (items.length > 0) {
      return items.map((item) => `&${prefix}=${item}`).join("");
    }
    return "";
  };

  useEffect(() => {
    const statusUrl = getCheckUrl("status", selectedSubStatus);
    setSelectedSubStatusUrl(statusUrl);
  }, [selectedSubStatus]);

  //  statusNames
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/epic/distribution/${projectId}?`;

    if (isSprint) {
      url += `sprintId=${sprintId}`;
    } else {
      url += `${dateUrl}`;
    }

    if (selectedSubStatusUrl) {
      url += selectedSubStatusUrl;
    }

    axios
      .get(url)
      .then((response) => {
        setAllEpicList(response?.data?.allEpics);
        setFilteredEpic(response?.data?.filteredEpic);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [isSprint, projectId, date, selectedSubStatusUrl, sprintId]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/epic/issuelevel/distribution/${projectId}?`;
    if (isSprint) {
      url += `sprintId=${sprintId}`;
    } else {
      url += `${dateUrl}`;
    }

    if (selectedSubStatusUrl) {
      url += selectedSubStatusUrl;
    }
    axios
      .get(url)
      .then((response) => {
        setIssueLevelEpics(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [isSprint, projectId, date, selectedSubStatusUrl, sprintId]);

  return (
    <div>
      <Box display={"flex"} alignItems={"center"} mb={2}>
        {!isSprint && (
          <div style={{ marginTop: -8 }}>
            <SelectField
              input={
                <Button
                  onClick={() => setOpenRange(true)}
                  style={{
                    border: "1px solid #b7b7b7",
                    width: "230px",
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "14px ",
                    paddingRight: "11px",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    height: "54px",
                    color: "black",
                  }}
                  endIcon={<DateRangeOutlinedIcon />}
                >
                  {getSelectedDate(date)}
                </Button>
              }
              onClick={() => setOpenRange(true)}
            />
            <RangeSelector
              open={openRange}
              setOpen={setOpenRange}
              pickerHandler={(val) => setDate(val)}
              value={date}
              isNextDate={false}
            />
          </div>
        )}
        <div>
          <CheckSelect
            names={statusList}
            setValue={(value) => {
              setSelectedSubStatus(value);
            }}
            value={selectedSubStatus}
            placeholder="Select Status"
          />
        </div>
        {config.project.AllLevelSummary && (
          <div onClick={() => setIsSummary(!isSummary)}>
            <div className="border px-3 pt-2 pb-3 rounded cursorPointer mx-3">
              Summary
            </div>
          </div>
        )}
      </Box>

      <Card sx={{ padding: "20px" }}>
        <Grid container mt={2} spacing={2} wrap="wrap">
          <Grid item xs={6}>
            <Card className="p-2">
              <h5 className="pb-4">All Epics</h5>
              <WorkAlocationChart
                data={allEpicList}
                name={"project"}
                height={"500px"}
              />
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className="p-2">
              <h5 className="pb-4">Status</h5>
              <WorkAlocationChart
                data={filteredEpic}
                name={"team"}
                height="500px"
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="p-2">
              <CommonTable columns={column} data={issueLevelEpics} />
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Drawer
        anchor={"right"}
        open={isSummary}
        onClose={() => setIsSummary(false)}
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <h4 className="text-center mb-4 border-bottom pb-3 border-dark">
            Epic Distribution Analysis Report
          </h4>

          <section style={{ marginBottom: "20px" }}>
            <h5>Summary</h5>
            <p style={{ fontSize: "16px" }}>
              The provided data outlines the distribution of efforts across
              various epics. Key statistics include:
            </p>
          </section>

          <section style={{ marginBottom: "20px" }}>
            <h5>Top Epics by Value:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>Support Service: 56.</li>
              <li>India Tiered Tax: 38.</li>
              <li>Data Engineering & Analytics: 36.</li>
              <li>Functional Test Automation Using Robot Framework: 32</li>
              <li>Quick Wins: 32</li>
            </ul>
          </section>

          <section style={{ marginBottom: "20px" }}>
            <h5>Additional Epics of Interest:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>ZUZU Mobile App: 16.</li>
              <li>Zeko Integration: 12</li>
              <li>LLM Integration: 10</li>
              <li>UI Quick Wins: 6</li>
            </ul>
          </section>

          <section style={{ marginBottom: "20px" }}>
            <h5>Inferences:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                "Support Service" and "India Tiered Tax" epics dominate resource
                allocation, highlighting their critical importance to ongoing
                projects.
              </li>
              <li>
                Significant investment in "Functional Test Automation" and "Data
                Engineering" suggests an emphasis on improving operational
                efficiency and analytics capabilities.
              </li>
              <li>
                Lower values for "LLM Integration" and "UI Quick Wins" indicate
                these are emerging or lower-priority areas.
              </li>
            </ul>
          </section>

          <section style={{ marginBottom: "20px" }}>
            <h5>Alerts:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                The presence of unnamed epics with high values (56) requires
                immediate clarification to ensure resource tracking and
                alignment with strategic goals.
              </li>
              <li>
                Limited allocation to LLM Integration (10) could result in
                underutilization of advanced technologies, potentially impacting
                innovation.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>Impact Analysis:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                The heavy emphasis on Support Services may strain resources,
                potentially delaying other critical initiatives.
              </li>
              <li>
                Balanced resource distribution across automation and analytics
                ensures operational enhancements but may need realignment to
                focus on strategic innovations like LLM integration.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>Recommendations:</h5>
            <ol style={{ fontSize: "16px" }}>
              <li>
                Clarify Unnamed Epics: Investigate unnamed epic entries (value:
                56 and 2) for proper categorization and prioritization.
              </li>
              <li>
                Balance Priorities: Review resource allocation to ensure
                emerging areas like LLM Integration receive adequate focus
                without compromising on high-impact epics.
              </li>
              <li>
                Monitor Strategic Epics: Regularly assess progress in high-value
                epics such as "India Tiered Tax" and "Data Engineering" to
                ensure alignment with business objectives.
              </li>
              <li>
                Enhance Visibility: Implement more granular tracking and
                reporting to minimize the presence of ambiguities in future
                distributions.
              </li>
            </ol>
          </section>
        </div>
      </Drawer>
    </div>
  );
};
