import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useEffect } from "react";

function WorkAlocationChart({
  data,
  name = "radiusPaiChart",
  showPercentage = false,
  height = "250px",
}) { 
  useEffect(() => {
    var chart = am4core.create(name, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = data;
    chart.padding(0, 20, 0, 0);
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    // series.dataFields.radiusValue = "value";
    series.dataFields.category = "title";
    series.slices.template.cornerRadius = 0;
    series.colors.step = 3;
    // Create a separate label template for the series
    var labelTemplate = series.labels.template;
    labelTemplate.text = "{value.percent.formatNumber('#.')}%";
    // labelTemplate.text = "{value.percent.formatNumber('#.#')}%";
    var customColors = [
      "#5EBDCC",
      "#8DDBE8",
      "#9DE6F2",
      "#2E7E8B",
      "#1B515A",
      "#33FFFF",
    ];
    series.colors.list = customColors.map((color) => am4core.color(color));

    series.hiddenState.properties.endAngle = -10;

    // Create a legend
    chart.legend = new am4charts.Legend();
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    chart.legend.markers.template.width = 12;
    chart.legend.markers.template.height = 12;
    chart.legend.position = "right"; // Align the legend to the right side
    chart.legend.labels.template.maxWidth = 190; // Adjust the legend label width as needed
    chart.legend.itemContainers.template.paddingTop = 2;
    chart.legend.itemContainers.template.paddingBottom = 2;
    chart.legend.valueLabels.template.disabled = true;
    if (showPercentage) {
      chart.legend.labels.template.text =
        "{title} ({value}) \n {value.percent.formatNumber('#.')}%";
    }

    // chart.legend.maxHeight = 500;  
    // chart.legend.scrollable = true;  

    // "{title} ({value}) \n {value.percent.formatNumber('#.#')}%";
    // Customize the legend labels

    return () => {
      chart.dispose();
    };
  }, [data,name,showPercentage]);

  return <div style={{ height: height }} id={name}></div>;
}

export default WorkAlocationChart;
