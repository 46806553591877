import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { red } from "@material-ui/core/colors";
import { format } from "date-fns";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { SortingONTitle } from "../../../utils/sortingOnTable";
import { TimeLine } from "../../../utils/levelMap";
import { Tooltip } from "@mui/material";
import InfoIcon from "@material-ui/icons/Info";
import { GlobalData } from "../../../context/globalData";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const useStyles = makeStyles({
  saveicon: {
    width: 30,
    height: 30,
    backgroundColor: red,
  },
  "MuiTab-root": {
    padding: 0,
  },
  tablecont: {
    marginTop: "40px",
    "& td": {
      padding: "20px",
    },
    "& td:last-child": {
      borderRight: "0px solid #80808047",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiTableCell-head": {
      boxShadow: "none",
      color: "black",
      fontWeight: "bold",
      background: "#F2F2F2 0% 0% no-repeat padding-box",
      textAlign: "center",
    },
  },
  stepContent: {
    "& label": {
      display: "block",
    },
  },
  bld: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  flx: {
    display: "flex",
    gap: "5px",
  },
  wd: {
    width: "15%",
    color: "#5ebdcc",
    cursor: "pointer",
    fontWeight: "500",
  },
  whtspc: {
    whiteSpace: "nowrap",
  },
  crd: {
    border: "1px solid lightgray",
    padding: "20px 26px ",
    marginTop: "20px",
    boxShadow: "5px 5px 17px lightgrey",
    borderRadius: "8px",
  },
  fl: {
    display: "flex",
    gap: "10px",
  },
});

export const ExpandJiraDynamicTable = (props) => {
  const { searchJiraIssue, keysOfObject, defectLog } = props;
  const [dataOfTable, setDataOfTable] = useState(searchJiraIssue);
  const [toggle, setToggle] = useState(false);
  const [indexToggle, setIndexToggle] = useState();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  useEffect(() => {
    if (searchJiraIssue) {
      setDataOfTable(searchJiraIssue);
    }
    // window.scrollTo(0, 500);
  }, [searchJiraIssue]);

  const sortData = (header, toggle, index) => {
    let data = SortingONTitle(header, toggle, index, dataOfTable);
    setIndexToggle(data?.indexOfHeader);
    setDataOfTable(data?.sortedDataOfTable);
  };

  const classes = useStyles();
  useEffect(() => {}, [searchJiraIssue]);
  return (
    <>
      {searchJiraIssue && searchJiraIssue.length > 0 && (
        <TableContainer
          component={Paper}
          className={classes.tablecont}
          elevation={0}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {keysOfObject?.map((header, index) => {
                  return (
                    header?.title !== "Expand" &&
                    header?.title !== "url" && (
                      <TableCell
                        align="left"
                        key={index}
                        onClick={() => {
                          setToggle(!toggle);
                          sortData(header, toggle, index);
                        }}
                      >
                        <div className={classes.fl}>
                          <div>{header?.title}</div>
                          <div>
                            {toggle && indexToggle === index ? (
                              <ArrowUpwardIcon style={{ fontSize: 15 }} />
                            ) : (
                              <ArrowDownwardIcon style={{ fontSize: 15 }} />
                            )}
                          </div>
                          <div
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI(header?.title);
                            }}
                          >
                            {header?.title === "Unassigned Work In Progress" ||
                            header?.title === "Slow Response" ||
                            header?.title === "Speedy Transition" ? (
                              <>
                                {" "}
                                <InfoIcon
                                  style={{ color: "gray" }}
                                  fontSize="small"
                                />
                              </>
                            ) : null}
                          </div>
                        </div>
                      </TableCell>
                    )
                  );
                })}
                <TableCell className={classes.wd} />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataOfTable &&
                dataOfTable?.map((item) => (
                  <ExapndRow
                    key={item?.ResolutionDate}
                    item={item}
                    keysOfObject={keysOfObject}
                    data={item?.Expand}
                    defectLog={defectLog}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const ExapndRow = (props) => {
  const { item, keysOfObject, data, defectLog } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isRotate, setIsRotae] = useState(false);
  const classes = useStyles();
  const [searchJiraIssueModify, setSearchJiraIssueModify] = useState([]);

  useEffect(() => {
    if (data?.changeLog) {
      const newData = data?.changeLog?.map((item) => {
        let dayValue;
        let dates = item?.diffInHours.split(",");
        let dataValue = dates[0].includes("d");
        if (dataValue === true) {
          dayValue = dates[0];
        } else {
          dayValue = item?.diffInHours;
        }
        return { ...item, diffInDays: dayValue };
      });
      setSearchJiraIssueModify(newData);
    }
  }, [data]);

  return (
    <>
      <TableRow
        onClick={(e) => {
          setIsOpen(!isOpen);
          setIsRotae(!isRotate);
        }}
      >
        {keysOfObject?.map(
          (content) =>
            content.title !== "Expand" &&
            content.title !== "url" && (
              <TableCell align="left" style={{ width: "12%" }}>
                <>
                  {content["title"] === "Epic Key" || content["title"] === "Issue Id" ? (
                    <a target="_blank" href={item.url} rel="noreferrer">
                      {item[content.keyItem]}
                    </a>
                  ) : (
                    <>
                      {content["title"] !== "Expand" && (
                        <label>
                          {content.keyItem === "CreatedDate"
                            ? item?.CreatedDate !== "No Date" &&
                              format(
                                new Date(item["CreatedDate"]),
                                "dd-MMM-yyyy"
                              )
                            : content.keyItem === "ResolutionDate"
                            ? item?.ResolutionDate !== "No Date" &&
                              format(
                                new Date(item["ResolutionDate"]),
                                "dd-MMM-yyyy"
                              )
                            : item[content.keyItem]}
                        </label>
                      )}
                    </>
                  )}
                </>
              </TableCell>
            )
        )}
        <TableCell className={classes.wd}>
          <ChevronRightIcon
            style={{
              transform: `rotate(${isRotate ? "90deg" : "0Deg"})`,
            }}
          />
          {!isRotate ? "View More" : "View Less"}
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <TableCell align="left" colSpan={keysOfObject?.length}>
            <div className="items-wrap-two">
              <div className="items-wrap">
                {/* Change log Code */}
                {defectLog === "logOne" ? (
                  <div className="arrow-container">
                    {searchJiraIssueModify?.map((content, index) => {
                      return (
                        <>
                          <div className="Arrow-main" key={index}>
                            <div
                              className="arrow-top-line"
                              style={{
                                background: `${TimeLine[content?.from]}`,
                              }}
                            ></div>
                            <Tooltip
                              title={content?.diffInHours}
                              placement="top"
                              arrow
                            >
                              <div className="contain">
                                <h3
                                  className={classes.whtspc}
                                  style={{
                                    color: `${TimeLine[content?.from]}`,
                                  }}
                                >
                                  {content?.from}
                                </h3>
                                <label>{content?.diffInDays}</label>
                              </div>
                            </Tooltip>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className="arrow-container">
                    {/* Work log Code */}
                    {data?.workLog?.map((content, index) => {
                      return (
                        <>
                          {content?.name && (
                            <div className="Arrow-main" key={index}>
                              <div
                                className="arrow-top-line"
                                style={{
                                  background: `${TimeLine[content?.name]}`,
                                }}
                              ></div>
                              <Tooltip
                                title={<>Time Spent:{content?.timeSpent}</>}
                                placement="top"
                                arrow
                              >
                                <div
                                  className="contain"
                                  style={{ marginLeft: "20px" }}
                                >
                                  <h6
                                    style={{
                                      color: "blue",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {content?.name}
                                  </h6>
                                  <div
                                    className="d-flex"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "14px",
                                        color: "#626cbf",
                                      }}
                                    >
                                      <div>
                                        {content?.started &&
                                          format(
                                            new Date(
                                              Date.parse(content?.started)
                                            ),
                                            "dd-MMM-yyyy"
                                          )}
                                      </div>
                                      {content?.started &&
                                        format(
                                          new Date(
                                            Date.parse(content?.started)
                                          ),
                                          "HH:mm:ss"
                                        )}
                                    </div>
                                    <div className="mx-2">
                                      <TrendingFlatIcon />
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "14px",
                                        color: "#626cbf",
                                      }}
                                    >
                                      <div>
                                        {content?.updated &&
                                          format(
                                            new Date(
                                              Date.parse(content?.updated)
                                            ),
                                            "dd-MMM-yyyy"
                                          )}
                                      </div>
                                      {content?.updated &&
                                        format(
                                          new Date(
                                            Date.parse(content?.updated)
                                          ),
                                          "HH:mm:ss"
                                        )}
                                    </div>
                                  </div>
                                  <div style={{ fontSize: "18px" }}>
                                    Time Spent: {content?.timeSpent}
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
