import { Card, Grid } from "@mui/material";
import React, { useState } from "react";
import PieChartIcon from "@mui/icons-material/PieChart";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import RequirementPopup from "./requirPopup";
import SelectField from "../../components/select/selectField";
import DependencyRequire from "./requirementDependancy";

const RequirementsAnalysis = () => {
  const [requirementsOpen, setRequirementsOpen] = useState(false);
  const [requirementAnalyticName, setRequirementAnalyticName] = useState();

  // Metric list
  const requirementAnalyticList = [
    {
      name: "Number of requirements received per day",
      icon: <EqualizerIcon fontSize="large" color="secondary" />,
    },
    {
      name: "Number of requirements status-wise",
      icon: <PieChartIcon fontSize="large" color="success" />,
    },
    {
      name: "Number of requirements priority-wise",
      icon: <EqualizerIcon fontSize="large" color="secondary" />,
    },
    {
      name: "Number of requirements clarity-wise",
      icon: <EqualizerIcon fontSize="large" color="secondary" />,
    },
    {
      name: "Number of requirements completeness-wise",
      icon: <PieChartIcon fontSize="large" color="success" />,
    },
    {
      name: "Number of requirements testability-wise",
      icon: <EqualizerIcon fontSize="large" color="secondary" />,
    },
    {
      name: "Number of requirements functionality-wise",
      icon: <EqualizerIcon fontSize="large" color="secondary" />,
    },
    {
      name: "Number of requirements dependability",
      icon: <EqualizerIcon fontSize="large" color="secondary" />,
    },
  ];

  // Open requirement popup on click
  const handleOpen = (e) => {
    setRequirementsOpen(true);
    setRequirementAnalyticName(e);
  };

  // Close requirement popup on click
  const handleClose = () => {
    setRequirementsOpen(false);
    setRequirementAnalyticName();
  };

  return (
    <div>
      {/* List of metrics */}
      <Grid container spacing={2}>
        {requirementAnalyticList?.map((data, index) => {
          return (
            <Grid item xs={3} key={index}>
              <Card className="px-4 py-4">
                <div className="d-flex align-items-center">
                  <div className="bg-light p-2 rounded-circle me-3">
                    {data?.icon}
                  </div>
                  <div>
                    <h5 className="mb-2">{data?.name}</h5>
                    <div
                      className="viewMore text-decoration-underline"
                      onClick={() => handleOpen(data)}
                    >
                      View More
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {/* Dependency Chart */}
      <DependencyRequire />
      {/* Popup for trends */}
      <RequirementPopup
        isOpen={requirementsOpen}
        handleClose={handleClose}
        requirementAnalyticNameType={requirementAnalyticName}
      />
    </div>
  );
};

export default RequirementsAnalysis;
