import { Box, Card } from "@mui/material";
import React from "react";

const DependCard = ({ issueName, status, description, issueLink }) => {
  return (
    <Card className="px-3 py-2 m-2 border">
      <h6
        className="text-primary"
        onClick={() => window.open(issueLink, "_blank")}
      >
        {issueName}
      </h6>
      <div className="text-truncate fs-6 mb-2">{description}</div>
      <Box display={"flex"} justifyContent={"space-between"}>
        <div></div>
        <ReqButton status={status} />
      </Box>
    </Card>
  );
};

export default DependCard;

export const ReqButton = ({ status }) => {
  const getStatusClass = (status) => {
    switch (status) {
      case "Done":
        return ["#17a62d","white"];
      case "Closed":
        return ["gray","white"];
      case "In Progress":
        return ["warning","white"];
      case "Open":
        return ["gray","white"];
      case "On Hold":
        return ["yellow","black"];
      case "In Progress":
        return ["gray","black"];
      default:
        return ["gray","white"];
    }
  };
  return (
    <div
      style={{
        backgroundColor: getStatusClass(status)[0],
        color: getStatusClass(status)[1],
        borderRadius: "4px",
        padding: "2px 6px",
        fontSize: "14px",
        lineHeight: 1.2,
      }}
    >
      {status}
    </div>
  );
};
