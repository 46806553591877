import { Box, Dialog, Tooltip } from "@mui/material";
import React from "react";
import SelectField from "../../../../../components/select/selectField";
import DependCard from "../../../../requirementsAnalysis/requirementDependancy/dependCard";
import nextArrow from "../../../../../assets/nextArrow.png";
import selectImg from "../../../../../assets/selectOption.jpg";

const HealthDependCard = ({
  open,
  close,
  issueList,
  setSelectedIssue,
  selectedIssue,
  issue,
  inWordAndOutWordKeys,
  issueListInDetail,
  keyOfName,
}) => {
  const keyOfIssue =
    issueListInDetail?.level === "Issue"
      ? "Key"
      : issueListInDetail?.level === "Team"
      ? "team"
      : "assignee";

  console.log(selectedIssue);

  return (
    <Dialog
      open={open}
      fullWidth={"xl"}
      maxWidth={"xl"}
      onClose={() => close()}
    >
      <Box p={2}>
        <h5>{issueListInDetail?.level} Dependencies</h5>
        <SelectField
          render={issueList}
          value={selectedIssue}
          setValue={(e) => setSelectedIssue(e)}
          placeholder="Select Issue..."
        />
        {selectedIssue ? (
          <>
            {issue?.map((item) => {
              let isInWord = 0;
              let isOutWord = 0;
              return (
                <div className="d-flex justify-content-around align-items-center">
                  {/* Inward Dependant card */}
                  <div>
                    {item?.Data?.map((inward, index) => {
                      // code for next arrow
                      inward?.IssueLinks?.inwardIssue?.key
                        ? (isInWord = isInWord + 1)
                        : (isInWord = isInWord + 0);

                      // code for next arrow
                      inward?.IssueLinks?.outwardIssue?.key
                        ? (isOutWord = isOutWord + 1)
                        : (isOutWord = isOutWord + 0);
                      return (
                        <div key={index}>
                          {inward?.IssueLinks?.inwardIssue?.key && (
                            <div style={{ width: "400px" }}>
                              <DependCard
                                issueName={
                                  inward?.IssueLinks?.inwardIssue?.[keyOfName]
                                }
                                status={
                                  inward?.IssueLinks?.inwardIssue?.fields
                                    ?.status?.name
                                }
                                description={
                                  inward?.IssueLinks?.inwardIssue?.fields
                                    ?.summary
                                }
                                issueLink={
                                  inward?.IssueLinks?.inwardIssue?.self
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {isInWord ? (
                    <Box className="p-4" width={200}>
                      <Tooltip
                        title={
                          <Box
                            fontSize={"14px"}
                          >{`${inWordAndOutWordKeys?.in} tickets blocking ${item?.Data[0][keyOfIssue]}.`}</Box>
                        }
                        placement="top"
                        arrow
                      >
                        <img src={nextArrow} alt="next" className="img-fluid" />
                      </Tooltip>
                    </Box>
                  ) : null}
                  {/* Main Dependant card */}
                  <div style={{ width: "400px" }}>
                    <DependCard
                      issueName={item?.Data[0][keyOfIssue]}
                      status={item?.Data[0].Status}
                      description={item?.Data[0].Summary}
                      issueLink={item?.Data[0].Url}
                    />
                  </div>
                  {isOutWord ? (
                    <Box className="p-4" width={200}>
                      <Tooltip
                        title={
                          <Box
                            fontSize={"14px"}
                          >{`${item?.Data[0][keyOfIssue]} ticket blocking ${inWordAndOutWordKeys?.out}.`}</Box>
                        }
                        placement="top"
                        arrow
                      >
                        <img src={nextArrow} alt="next" className="img-fluid" />
                      </Tooltip>
                    </Box>
                  ) : null}

                  {/* Outward Dependant card */}
                  <div>
                    {item?.Data?.map((outWard, index) => {
                      return (
                        <div key={index}>
                          {outWard?.IssueLinks?.outwardIssue?.key && (
                            <div style={{ width: "400px" }}>
                              <DependCard
                                issueName={
                                  outWard?.IssueLinks?.outwardIssue?.[keyOfName]
                                }
                                status={
                                  outWard?.IssueLinks?.outwardIssue?.fields
                                    ?.status?.name
                                }
                                description={
                                  outWard?.IssueLinks?.outwardIssue?.fields
                                    ?.summary
                                }
                                issueLink={
                                  outWard?.IssueLinks?.outwardIssue?.self
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <div>
              <img
                src={selectImg}
                alt="select"
                className="img-fluid"
                width={300}
              />
              <div style={{ color: "gray" }} className="text-center" >Please select the issue</div>
            </div>
          </div>
        )}
      </Box>
    </Dialog>
  );
};

export default HealthDependCard;
