import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import instance from "../../../utils/axiosHelper";
import WorkAlocationChart from "../../Project/projectDashboard/graphs/workAlocationChart";
import CancelIcon from "@mui/icons-material/Cancel";
import TrendBar from "../../Project/projectDashboard/trend/TrendBar";
import { format } from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const RequirementPopup = ({
  isOpen,
  handleClose,
  requirementAnalyticNameType,
}) => {
  const [graphData, setGraphData] = useState([]);

  // Create url.
  useEffect(() => {
    if (
      requirementAnalyticNameType?.name === "Number of requirements status-wise"
    ) {
      let url = "/api/v2/requirementAnalytic/statusCount";
      apiCall(url);
    } else if (
      requirementAnalyticNameType?.name ===
      "Number of requirements completeness-wise"
    ) {
      let url = "/api/v2/requirementAnalytic/completenessCount";
      apiCall(url);
    } else if (
      requirementAnalyticNameType?.name ===
      "Number of requirements received per day"
    ) {
      let url = "/api/v2/requirementAnalytic/receivedPerDay";
      apiCall(url);
    } else if (
      requirementAnalyticNameType?.name ===
      "Number of requirements priority-wise"
    ) {
      let url = "/api/v2/requirementAnalytic/priorityCount";
      apiCall(url);
    } else if (
      requirementAnalyticNameType?.name ===
      "Number of requirements clarity-wise"
    ) {
      let url = "/api/v2/requirementAnalytic/clarityCount";
      apiCall(url);
    } else if (
      requirementAnalyticNameType?.name ===
      "Number of requirements testability-wise"
    ) {
      let url = "/api/v2/requirementAnalytic/testabilityCount";
      apiCall(url);
    } else if (
      requirementAnalyticNameType?.name ===
      "Number of requirements functionality-wise"
    ) {
      let url = "/api/v2/requirementAnalytic/functionalityCount";
      apiCall(url);
    } else if (
      requirementAnalyticNameType?.name ===
      "Number of requirements dependability"
    ) {
      let url = "/api/v2/requirementAnalytic/dependency";
      apiCall(url);
    }
  }, [requirementAnalyticNameType]);

  // Api call with parameters(name)
  const apiCall = (url) => {
    instance
      .get(url)
      .then((response) => {
        if (
          requirementAnalyticNameType?.name ===
          "Number of requirements status-wise"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              title: data._id,
              value: data.count,
            };
          });
          setGraphData(statusCount);
        } else if (
          requirementAnalyticNameType?.name ===
          "Number of requirements completeness-wise"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              title: data._id,
              value: data.count,
            };
          });
          setGraphData(statusCount);
        } else if (
          requirementAnalyticNameType?.name ===
          "Number of requirements received per day"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              category: format(new Date(data?._id), "MM-dd-yyyy"),
              Count: data?.count,
            };
          });
          setGraphData(statusCount);
        } else if (
          requirementAnalyticNameType?.name ===
          "Number of requirements priority-wise"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              category: data?._id,
              Count: data?.count,
            };
          });
          setGraphData(statusCount);
        } else if (
          requirementAnalyticNameType?.name ===
          "Number of requirements clarity-wise"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              category: data?._id,
              Count: data?.count,
            };
          });
          setGraphData(statusCount);
        } else if (
          requirementAnalyticNameType?.name ===
          "Number of requirements testability-wise"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              category: data?._id,
              Count: data?.count,
            };
          });
          setGraphData(statusCount);
        } else if (
          requirementAnalyticNameType?.name ===
          "Number of requirements functionality-wise"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              category: data?._id,
              Count: data?.count,
            };
          });
          setGraphData(statusCount);
        } else if (
          requirementAnalyticNameType?.name ===
          "Number of requirements dependability"
        ) {
          const statusCount = response.data.map((data) => {
            return {
              category: data?._id,
              Count: data?.count,
            };
          });
          setGraphData(statusCount);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // components executed form hear.
  const getRequireMetric = (name) => {
    switch (name) {
      case "Number of requirements status-wise":
      case "Number of requirements completeness-wise":
        return (
          <Box sx={{ width: 800 }}>
            <WorkAlocationChart data={graphData} />
          </Box>
        );
      case "Number of requirements received per day":
      case "Number of requirements priority-wise":
      case "Number of requirements clarity-wise":
      case "Number of requirements testability-wise":
      case "Number of requirements functionality-wise":
      case "Number of requirements dependability":
        return (
          <Box sx={{ width: 1200 }}>
            <TrendBar
              graphData={graphData}
              yAxesLabel={"Count"}
              issueType={requirementAnalyticNameType?.name}
            />
          </Box>
        );
      default:
        return <>Fail</>;
    }
  };

  return (
    <Modal
      open={isOpen}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="d-flex justify-content-between mb">
          <h4>{requirementAnalyticNameType?.name}</h4>
          <div onClick={handleClose} className="cursorPointer">
            <CancelIcon />
          </div>
        </div>
        {getRequireMetric(requirementAnalyticNameType?.name)}
      </Box>
    </Modal>
  );
};

export default RequirementPopup;
