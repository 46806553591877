import { Box, Dialog, Divider } from "@mui/material";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import CommitIcon from "@mui/icons-material/Commit";
import BestPractices from "./BestPractices";
import IssueLevel from "../../IssueLevel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import IssueLevelRiskSummary from "./IssueLevelRisk";
import { addSpace } from "../../../../../utils/spaceSeprator";
import VisibilityIcon from '@mui/icons-material/Visibility';

const RiskSummary = ({
  open,
  onClose,
  riskSummaryData,
  projectId,
  date,
  epic,
}) => {
  const [issueType, setIssueType] = useState({});
  const [openSecondPopup, setOpenSecondPopup] = useState(false);

  const handleClose = () => {
    onClose(false);
  };

  const handleCloseSecond = () => {
    setOpenSecondPopup(false);
  };

  const handleGetTable = (name,values) => {
    console.log(name,"======",values)
    setOpenSecondPopup(true);
    setIssueType({name : name,values:values});
  };

  // Total Calculation
  const totalIssue = riskSummaryData?.Issues?.reduce((acc, curr) => {
    return acc + curr.Count;
  }, 0);
  const totalPr = riskSummaryData?.PullRequests?.reduce((acc, curr) => {
    return acc + curr.Count;
  }, 0);
  const totalCommit = riskSummaryData?.Commits?.reduce((acc, curr) => {
    return acc + curr.Count;
  }, 0);
  const totalScopeChange = riskSummaryData?.ScopeChange?.reduce((acc, curr) => {
    return acc + curr.Count;
  }, 0);
  return (
    <div>
      <Dialog open={open}>
        <Box p={2} className="d-flex justify-content-between">
          <strong>
            <WarningIcon color="warning" />
            Risk Summary
          </strong>
          <div onClick={() => handleClose()}>
            <CancelIcon />
          </div>
        </Box>
        <Divider />
        <Box width={500} m={2}>
          {/* ============  Issue and Story ============= */}
          <div className="mb-2">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <h5>
                <SpeakerNotesIcon size="small" /> Issue and Story
              </h5>
              <Box backgroundColor={"#fef2c7"} className="px-1" fontSize={13}>
                {totalIssue} Risk
              </Box>
            </Box>
            {riskSummaryData?.Issues?.map((item) => (
              <>
                {item?.Count > 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    backgroundColor={"#e3f3fe"}
                    mb={1}
                    p={1}
                    fontSize={16}
                    borderRadius={1}
                  >
                    <div>{`${item?.Count} Issues are in ${item?.Issuetype} state`}</div>
                    <div
                      className="viewMore"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleGetTable(item?.Issuetype,item?.Issuetype)}
                    >
                      View <VisibilityIcon fontSize="small" />
                    </div>
                  </Box>
                )}
              </>
            ))}
          </div>
          {/* ============ Pull Request ============= */}
          <div className="mt-3">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <h5>
                <DeviceHubIcon size="small" /> Pull Request
              </h5>
              <Box backgroundColor={"#fef2c7"} className="px-1" fontSize={13}>
                {totalPr} Risk
              </Box>
            </Box> 
            {riskSummaryData?.PullRequests?.map((item) => (
              <>
                {item?.Count > 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    backgroundColor={"#e3f3fe"}
                    mb={1}
                    p={1}
                    fontSize={16}
                    borderRadius={1}
                  >
                    <div>{`${item?.Count} Issues are in ${addSpace(item?.Issuetype)} state`}</div>
                    <div
                      className="viewMore"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleGetTable(addSpace(item?.Issuetype),item?.Issuetype)}
                    >
                      View <VisibilityIcon fontSize="small" />
                    </div>
                  </Box>
                )}{" "}
              </>
            ))}
          </div>
          {/*  ============ Commits =============  */}
          <div className="mt-3">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <h5>
                <CommitIcon size="small" /> Commits
              </h5>
              <Box backgroundColor={"#fef2c7"} className="px-1" fontSize={13}>
                {totalCommit} Risk
              </Box>
            </Box>
            {riskSummaryData?.Commits?.map((item) => (
              <>
                {item?.Count > 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    backgroundColor={"#e3f3fe"}
                    mb={1}
                    p={1}
                    fontSize={16}
                    borderRadius={1}
                  >
                    <div>{`${item?.Count} Issues are in ${addSpace(item?.Issuetype)} state`}</div>
                    <div
                      className="viewMore"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleGetTable(addSpace(item?.Issuetype),item?.Issuetype)}
                    >
                      View <VisibilityIcon fontSize="small" />
                    </div>
                  </Box>
                )}
              </>
            ))}
          </div>
          {/* ============ Scope change ============= */}
          <div className="mt-3">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <h5>
                <ChangeCircleIcon size="small" />
                Scope change
              </h5>
              <Box backgroundColor={"#fef2c7"} className="px-1" fontSize={13}>
                {totalScopeChange} Risk
              </Box>
            </Box>
            {riskSummaryData?.ScopeChange?.map((item) => (
              <>
                {item?.Count > 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    backgroundColor={"#e3f3fe"}
                    mb={1}
                    p={1}
                    fontSize={16}
                    borderRadius={1}
                  >
                    <div>{`${item?.Count} Issues are in ${addSpace(item?.Issuetype)} state`}</div>
                    <div
                      className="viewMore"
                      style={{ fontSize: "12px" }}
                      onClick={() => handleGetTable(addSpace(item?.Issuetype),item?.Issuetype)}
                    >
                      View <VisibilityIcon fontSize="small" />
                    </div>
                  </Box>
                )}
              </>
            ))}
          </div>
        </Box>
      </Dialog>


      {/* ======== Second Popup ======== */}
      <Dialog open={openSecondPopup} maxWidth={"lg"}>
        <Box
          display={"flex"}
          p={2}
          justifyContent={"end"}
          onClick={() => handleCloseSecond()}
        >
          <CancelIcon />
        </Box>
        <Box>
          <IssueLevelRiskSummary
            issueType={issueType}
            projectId={projectId}
            epic={epic}
          />
        </Box>
      </Dialog>
    </div>
  );
};

export default RiskSummary;
