import { Box, Drawer } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import EpicDropDown from "../../components/epicDropDown";
import RangeDatePicker from "../../components/RangeDatePicker";
import { GlobalData } from "../../context/globalData";
import DoraHeader from "./Header";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../utils/moduleTabApi";
import axios from "../../utils/axiosHelper";
import { getMetricRange } from "../../utils/getMetricRange";
import NormalSelect from "../../components/select/normalSelect";
import instance from "../../utils/axiosHelper";
import CheckSelect from "../../components/select/CheckSelect";
import HeaderAws from "./HeaderAws";
import { config } from "../../Config/permission";

const Dora = ({ projectId }) => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [doraMetrics, setDoraMetrics] = useState();
  const [range, setRange] = useState();
  const [allTeamList, setAllTeamList] = useState([]);
  const [jobList, setAllJobList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  // Multiple Select
  // const [selectedTeamUrl, setSelectedTeamUrl] = useState();
  const [jobs, setJobs] = useState();
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [isSummary, setIsSummary] = useState(false);

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  const doraMetricsNumbers = () => {
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    let url = `/api/v2/projects/doraMatric/${projectId}?${zeroDate}`;
    // Multiple Select
    // if (selectedTeamUrl) {
    //   url += `&${selectedTeamUrl}`;
    // }
    if (jobs) {
      url += `&Job=${jobs}`;
    }
    if (selectedTeam) {
      url += `&teamId=${selectedTeam}`;
    }
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setDoraMetrics(res?.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // get ranges
  useEffect(() => {
    let url = `/api/risks?ProjectId=${projectId}`;
    if (epicData?.url) {
      url += epicData?.url;
    }
    axios
      .get(url)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);

  useEffect(() => {
    doraMetricsNumbers();
  }, [date, jobs, selectedTeam]);

  const DeploymentFrequencyRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.deploymentFrequency,
        "Deployment Frequency"
      ),
    [doraMetrics?.deploymentFrequency, range]
  );

  const ChangeFailureRateRisk = useMemo(
    () =>
      getMetricRange(range, doraMetrics?.changeFailure, "Change Failure Rate"),
    [doraMetrics?.changeFailure, range]
  );
  const DeploymentRollbackRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.deploymentRollback,
        "Deployment Rollback (Hour)"
      ),
    [doraMetrics?.deploymentRollback, range]
  );

  const MeanLeadTimeRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.meanLeadTimeChange,
        "Mean Lead Time (Hour)"
      ),
    [doraMetrics?.meanLeadTimeChange, range]
  );

  const MeanTimeToRestoreRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.meanTimeToRestore,
        "Mean Time To Restore (Day's)"
      ),
    [doraMetrics?.meanTimeToRestore, range]
  );

  console.log("MeanTimeToRestoreRisk", MeanTimeToRestoreRisk);

  // All Team List Api call
  useEffect(() => {
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    let url = `/api/v2/teams/list/kpis?${zeroDate}&iteration=7&project_id=${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return { label: item?.TeamName, value: item?.Id };
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // All Job List Api call
  useEffect(() => {
    let url = `/api/v2/projects/joblist/${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return { label: item, value: item };
        });
        setAllJobList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Multiple Select
  // useEffect(() => {
  //   if (selectedTeam?.length > 0) {
  //     let url = " ";
  //     selectedTeam.forEach((e, index) => {
  //       if (index === 0) {
  //         url += `&teamName=${e}`;
  //       } else {
  //         url += `&teamName=${e}`;
  //       }
  //     });
  //     setSelectedTeamUrl(url.trim(), selectedTeam);
  //   } else {
  //     setSelectedTeamUrl("", selectedTeam);
  //   }
  // }, []);

  return (
    <div>
      <Box display={"flex"} gap={2}>
        {/* <EpicDropDown epicDropDownHandler={epicDropDownHandler} />  */}
        <>
          {/* select dropdown of month */}
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div>
            <label></label>
            <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
          </div>
          <div>
            <label>Select Jobs</label>
            <NormalSelect
              option={jobList}
              handleChange={(value) => {
                setJobs(value);
              }}
              value={jobs}
              // style={{ width: "200px" }}
            />
          </div>
          <div>
            {/* MultiSelect Team */}
            {/* <CheckSelect
              names={allTeamList}
              setValue={(value) => {
                setSelectedTeam(value);
              }}
              value={selectedTeam}
            /> */}
            <label>Select Team</label>
            <NormalSelect
              option={allTeamList}
              handleChange={(value) => {
                setSelectedTeam(value);
              }}
              value={jobs}
              // style={{ width: "200px" }}
            />
          </div>
          {config.project.AllLevelSummary && (
            <div onClick={() => setIsSummary(!isSummary)}>
              <div className="border mt-4 px-3 pt-2 pb-3 rounded cursorPointer">
                Summary
              </div>
            </div>
          )}
        </>
      </Box>
      {doraMetrics?.DataSource === "AWS CICD" ? (
        <HeaderAws
          riskAndRange={{
            DeploymentFrequencyRisk: {
              risk: DeploymentFrequencyRisk?.RiskCategory,
              from: DeploymentFrequencyRisk?.From?.$numberDecimal,
              to: DeploymentFrequencyRisk?.To?.$numberDecimal,
            },
            ChangeFailureRateRisk: {
              risk: ChangeFailureRateRisk?.RiskCategory,
              from: ChangeFailureRateRisk?.From?.$numberDecimal,
              to: ChangeFailureRateRisk?.To?.$numberDecimal,
            },
            DeploymentRollbackRisk: {
              risk: DeploymentRollbackRisk?.RiskCategory,
              from: DeploymentRollbackRisk?.From?.$numberDecimal,
              to: DeploymentRollbackRisk?.To?.$numberDecimal,
            },
            MeanLeadTimeRisk: {
              risk: MeanLeadTimeRisk?.RiskCategory,
              from: MeanLeadTimeRisk?.From?.$numberDecimal,
              to: MeanLeadTimeRisk?.To?.$numberDecimal,
            },
            meanTimeToRestore: {
              risk: MeanTimeToRestoreRisk?.RiskCategory,
              from: MeanTimeToRestoreRisk?.From?.$numberDecimal,
              to: MeanTimeToRestoreRisk?.To?.$numberDecimal,
            },
          }}
          doraMetrics={doraMetrics}
          projectId={projectId}
          jobs={jobs}
          selectedTeam={selectedTeam}
          jobList={jobList.map((e) => e?.label)}
        />
      ) : (
        <DoraHeader
          riskAndRange={{
            DeploymentFrequencyRisk: {
              risk: DeploymentFrequencyRisk?.RiskCategory,
              from: DeploymentFrequencyRisk?.From?.$numberDecimal,
              to: DeploymentFrequencyRisk?.To?.$numberDecimal,
            },
            ChangeFailureRateRisk: {
              risk: ChangeFailureRateRisk?.RiskCategory,
              from: ChangeFailureRateRisk?.From?.$numberDecimal,
              to: ChangeFailureRateRisk?.To?.$numberDecimal,
            },
            DeploymentRollbackRisk: {
              risk: DeploymentRollbackRisk?.RiskCategory,
              from: DeploymentRollbackRisk?.From?.$numberDecimal,
              to: DeploymentRollbackRisk?.To?.$numberDecimal,
            },
            MeanLeadTimeRisk: {
              risk: MeanLeadTimeRisk?.RiskCategory,
              from: MeanLeadTimeRisk?.From?.$numberDecimal,
              to: MeanLeadTimeRisk?.To?.$numberDecimal,
            },
            meanTimeToRestore: {
              risk: MeanTimeToRestoreRisk?.RiskCategory,
              from: MeanTimeToRestoreRisk?.From?.$numberDecimal,
              to: MeanTimeToRestoreRisk?.To?.$numberDecimal,
            },
          }}
          doraMetrics={doraMetrics}
          projectId={projectId}
          jobs={jobs}
          selectedTeam={selectedTeam}
        />
      )}

      <Drawer
        anchor={"right"}
        open={isSummary}
        onClose={() => setIsSummary(false)}
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <h4 className="text-center mb-4 border-bottom pb-3 border-dark">
            DORA Metrics Analysis Report
          </h4>

          {/* Summary Section */}
          <section style={{ marginBottom: "20px" }}>
            <h5>Summary</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Deployment Frequency:{" "}
                <strong>1411 deployments via AWS CICD</strong>.
              </li>
              <li>
                Mean Lead Time for Changes: <strong>0.07 days</strong>.
              </li>
              <li>
                Mean Time to Restore: <strong>18 minutes</strong>.
              </li>
              <li>
                Change Failure Rate: <strong>11%</strong>.
              </li>
              <li>
                Total Attempts: <strong>1582</strong> (1411 successes, 171
                failures).
              </li>
            </ul>
          </section>

          {/* Inferences Section */}
          <section style={{ marginBottom: "20px" }}>
            <h5>Inferences</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                A high deployment frequency indicates a mature and active CI/CD
                pipeline.
              </li>
              <li>
                The mean lead time for changes being under a day reflects
                efficient development and delivery.
              </li>
              <li>
                A change failure rate of 11% is within acceptable limits but
                indicates room for improvement.
              </li>
              <li>
                Rapid recovery time of 18 minutes highlights strong incident
                management practices.
              </li>
            </ul>
          </section>

          {/* Alerts Section */}
          <section style={{ marginBottom: "20px" }}>
            <h5>Alerts</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Failure rate at 11% should be monitored to identify recurring
                issues.
              </li>
              <li>
                Ensure high deployment frequency does not compromise quality
                control.
              </li>
            </ul>
          </section>

          {/* Impact Analysis Section */}
          <section style={{ marginBottom: "20px" }}>
            <h5>Impact Analysis</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Continuous improvements in lead time and restoration times
                contribute to reduced downtime and enhanced system reliability.
              </li>
              <li>
                A stable failure rate and rapid mean time to restore minimize
                operational disruptions.
              </li>
            </ul>
          </section>

          {/* Recommendations Section */}
          <section style={{ marginBottom: "20px" }}>
            <h5>Recommendations</h5>
            <ol style={{ fontSize: "16px" }}>
              <li>
                Analyse failure cases to identify root causes and reduce the
                change failure rate.
              </li>
              <li>
                Maintain rigorous automated testing to ensure deployment
                quality.
              </li>
              <li>
                Continuously monitor system health post-deployment for early
                detection of issues.
              </li>
              <li>
                Leverage incident management insights to further optimize mean
                time to restore.
              </li>
            </ol>
          </section>
        </div>
      </Drawer>
    </div>
  );
};

export default Dora;
