import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalData } from "../../../../../context/globalData";
import {
  datePayload,
  getSelectedDate,
} from "../../../../../utils/moduleTabApi";
import instance from "../../../../../utils/axiosHelper";
import { Button, Card } from "@mui/material";
import NormalSelect from "../../../../../components/select/normalSelect";
import SelectField from "../../../../../components/select/selectField";
import RangeSelector from "../../../../../components/rangeSelector";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import MultipleTooltipTrend from "./MultipleTooltipTrend";

const MultipleTooltipIndex = ({ issueType, epic, projectId, teamId }) => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [data, setData] = useState([]);
  const [iteration, setIteration] = useState(30);
  const [openRangeTrend, setOpenRangeTrand] = useState(false);
  //   const myDivRef = useRef(null);

  useEffect(() => {
    const dateUrl = datePayload(date);
    if (issueType === "Knowledge Sharing Index") {
      let url = `/api/v2/projects/ksiTrends/?projectId=${projectId}&${dateUrl}&iteration=${iteration}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Knowledge Sharing Index") {
      let url = `/api/v2/projects/ksiTrends/?projectId=${projectId}&${dateUrl}&iteration=${iteration}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    }
  }, [date, projectId, iteration, epic, teamId]);

  const api = (url) => {
    instance(url)
      .then((res) => {
        if (issueType === "Knowledge Sharing Index") {
          const resData = res?.data?.map((e) => {
            return {
              StartDate: e?.StartDate,
              prCount: e.prCount,
              averageKSI: e.averageKSI,
              availableReviewers: e.availableReviewers,
              activeReviewers: e.activeReviewers,
              submitters: e.submitters,
            };
          });
          setData(resData);
        } else if (issueType === "Team Knowledge Sharing Index") {
          const resData = res?.data?.map((e) => {
            return {
              StartDate: e?.StartDate,
              prCount: e.prCount,
              averageKSI: e.averageKSI,
              availableReviewers: e.availableReviewers,
              activeReviewers: e.activeReviewers,
              submitters: e.submitters,
            };
          });
          setData(resData);
        }
      })
      .catch((error) => {
        setData([]);
        console.log(error);
      });
  };

  const yAxesLabelText = (issueType) => {
    switch (issueType) {
      case "Knowledge Sharing Index":
        return "Avg Values";
      default:
        break;
    }
  };

  const yAxesLabel = yAxesLabelText(issueType);

  return (
    <div>
      <Card className="customCard" style={{ marginTop: "30px", width: "100%" }}>
        <h4 className="p-3">{issueType} Trend</h4>
        <div
          style={{
            width: "300px",
            paddingLeft: 20,
            paddingTop: 20,
            display: "flex",
            gap: 10,
            width: 500,
          }}
        >
          <NormalSelect
            option={[
              { label: "Monthly", value: 30 },
              { label: "Biweekly", value: 14 },
              { label: "Weekly", value: 7 },
              { label: "Daily", value: 1 },
            ]}
            value={iteration}
            handleChange={(value) => setIteration(value)}
          />
          {date && (
            <div>
              {/* Date drop down */}
              <SelectField
                input={
                  <Button
                    onClick={() => setOpenRangeTrand(true)}
                    style={{
                      border: "1px solid #b7b7b7",
                      width: "230px",
                      textTransform: "capitalize",
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingLeft: "14px ",
                      paddingRight: "11px",
                      justifyContent: "space-between",
                      borderRadius: "25px",
                      height: "48px",
                      color: "black",
                    }}
                    endIcon={<DateRangeOutlinedIcon />}
                  >
                    {getSelectedDate(date)}
                  </Button>
                }
                onClick={() => setOpenRangeTrand(true)}
              />
              <RangeSelector
                isNextDate={false}
                open={openRangeTrend}
                setOpen={setOpenRangeTrand}
                pickerHandler={(val) => setDate(val)}
                value={date}
              />
            </div>
          )}
        </div>
        <MultipleTooltipTrend graphData={data} yAxesLabel={yAxesLabel} />
      </Card>
    </div>
  );
};

export default MultipleTooltipIndex;
