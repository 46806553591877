import { Alert, Box, Button, Card, Modal, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectField from "../../../../components/select/selectField";
import upload from "../../../../assets/upload.jpg";
import instance from "../../../../utils/axiosHelper";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";

const ProjectRequireDocs = () => {
  const [prdData, setPrdData] = useState([]);
  const [prdIdList, setPrdIdList] = useState([]);
  const [selectedPrd, setSelectedPrd] = useState();
  const [selectedPrdResponse, setSelectedPrdResponse] = useState([]);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedPrd();
    setSelectedPrdResponse([]);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 700, 
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
    overflowY: "scroll",
    overflowX: "clip",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    height: "auto",
    overflowY: "auto",
    backgroundColor: "white",
    border: "1px solid transparent",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
  };

  const getFile = (file) => {
    handleOpen();

    const formData = new FormData();
    formData.append("file", file);
    getPrdData(formData, file);

  };

  const getPrdData = (pdfFile, view) => {
    const url = `/api/v2/prdRoutes/upload`;
    instance
      .post(url, pdfFile, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response?.data) {
          const data = response?.data;
          setPrdData(data);
          // prd list
          const list = data?.map((item) => {
            return { lable: `Story-${item?.Id}`, value: item?.Id };
          });
          // then show uploded prd
          setPrdIdList(list);
          setImage(URL.createObjectURL(view)); // Generate preview URL

        }

      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const data = prdData?.filter((e) => e?.Id === selectedPrd?.value);
    setSelectedPrdResponse(data);
  }, [selectedPrd]);

  const addStory = (type) => {
    let url = `/api/v2/prdRoutes/storyCreate`;
    let data = { stories: [] }
    if (type === "single") {
      data.stories.push(selectedPrdResponse[0])
    } else {
      data.stories.push(...prdData)
    }
    instance
      .post(url, data)
      .then((response) => {
        if (response) {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <Card sx={{ padding: 1 }}>
      <h4 className="p-3">Project Requirements Document</h4>

      <Box width={400} mx={"auto"}>
        <img src={upload} alt="Upload Img" className="img-fluid" />
        <div className="text-center">
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload files
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => getFile(event.target.files[0])}
              multiple
              accept="application/pdf"
            />
          </Button>
        </div>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box padding={2} sx={style}>
          <Box display={"flex"} justifyContent={"space-between"} mb={2}>
            <div className="d-flex align-items-center">
              <SelectField
                render={prdIdList}
                value={selectedPrd}
                setValue={(e) => setSelectedPrd(e)}
                placeholder="Select Story..."
                withLable={true}
              />
              {selectedPrd && (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => addStory('single')}
                    sx={{
                      padding: "10px 20px",
                      margin: "0 10px",
                      borderRadius: "30px",
                    }}
                  >
                    Add {selectedPrd?.lable} to Jira
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => addStory("all")}
                    sx={{
                      padding: "10px 20px",
                      margin: "0 10px",
                      borderRadius: "30px",
                    }}
                  >
                    Add All Story to Jira
                  </Button>
                </>
              )}
            </div>
            <Box onClick={() => handleClose()} className="cursorPointer">
              <HighlightOffIcon />
            </Box>
          </Box>
          <div className="d-flex ">
            <Box mt={1}>
              {selectedPrdResponse?.map((item) => (
                <Box>
                  <h5 className="mb-2">
                    Project Requirements Document Detail :{" "}
                  </h5>
                  <Box p={1}>
                    {Object?.keys(item?.data)?.map((e) => (
                      <Box display={"flex"}>
                        <Box
                          width={"20%"}
                          textAlign={"left"}
                          fontWeight={"bold"}
                        >
                          {e}
                        </Box>
                        <Box width={"10%"} textAlign={"left"}>
                          :
                        </Box>
                        <Box width={"70%"} textAlign={"left"}>
                          {item?.data[e]}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box ml={1}>
              <h5 className="mb-2">Project Requirements Document PDF : </h5>
              {image && (
                <iframe
                  src={image}
                  title={"test"}
                  style={{
                    width: "550px",
                    height: "100%",
                    minHeight: "400px",
                    marginTop: "10px",
                  }}
                ></iframe>
              )}
            </Box>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isSuccess}
            autoHideDuration={3000}
            key="top-right"
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Jira Tickets created successfully
            </Alert>
          </Snackbar>
        </Box>
      </Modal>
    </Card>
  );
};

export default ProjectRequireDocs;
