import * as React from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../../utils/axiosHelper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState, useEffect } from "react";
import ConfirmationDialog from "../../../components/confirmationPopup";
import CommonSettingTable from "../../../components/table/commonSettingTable";
import { useParams, useHistory } from "react-router-dom";
import { iterationMap } from "../../../utils/levelMap";
import { format, parseISO } from "date-fns";

export default function ReportList({ classes }) {
  // const classes = useStyles();
  const [reportList, setReportList] = useState();
  const [deleteReportDetail, setDeleteReportDetail] = useState();
  const [open, setOpen] = useState(false);
  const { settingTab } = useParams();
  const history = useHistory();

  const columns = [
    {
      field: "Reports",
      title: "Report Name",
      width: "150px",
      render: (row) => row?.Reports?.map((e) => e).join(", "),
    },
    {
      field: "Projects",
      title: "Project Name",
      width: "150px",
      render: (row) => row?.Projects?.map((e) => e?.projectName).join(","),
    },
    {
      field: "Iteration",
      title: "Iteration",
      width: "150px",
      render: (row) => iterationMap[row.Iteration],
    },
    {
      field: "CreatedDate",
      title: "Created Date",
      width: "150px",
      render: (row) =>
        row.CreatedDate
          ? format(parseISO(row.CreatedDate), "dd-MMM-yyyy")
          : "-",
    },
    {
      field: "Email",
      title: "Email",
      width: "150px",
    },
    {
      field: "Actions",
      title: "Actions",
      width: "150px",
      render: (row) => (
        <Grid item xs={4} sx={{ ml: 3 }}>
          <EditIcon
            className={classes?.RiskIcons}
            onClick={() => {
              history.push({
                pathname: `/settings/${settingTab}/form/report`,
                state: { row },
              });
            }}
            style={{ cursor: "pointer", color: "#5EBDCC" }}
          />
          <DeleteOutlinedIcon
            className={classes?.RiskIcons}
            style={{ cursor: "pointer", color: "#5EBDCC" }}
            xs={6}
            sx={{ ml: 3 }}
            onClick={() => {
              setOpen(true);
              setDeleteReportDetail(row);
            }}
          />
        </Grid>
      ),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  // get List
  const getList = () => {
    let ClientID = localStorage.getItem("ClientId");
    axios
      .get(`/api/v2/reportGenerate`)
      .then((result) => {
        setReportList(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // add page handler
  const AddEditReport = () => {
    history.push(`/settings/${settingTab}/form/Report`);
  };
  // delete Handler
  const deleteReport = () => {
    axios
      .delete(`/api/v2/reportGenerate/${deleteReportDetail._id}`)
      .then((result) => {
        setOpen(false);
        getList();
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <>
        <CommonSettingTable
          columns={columns}
          clickHandler={AddEditReport}
          btnLabel={"New Report"}
          data={reportList}
          tableTitle={"Reports"}
        ></CommonSettingTable>
      </>

      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={"Delete"}
        text={"Are you sure you want to delete?"}
        deleteHandler={deleteReport}
      />
    </>
  );
}
